@import "variables";
@import "antd-carousel";
@import "antd";
@import "polotno";
@import "form";
@import "contact";
@import "footer";
@import "tracker";
@import "acm-loader";
@import "markers";
@import "PropertyDetails";
@import "../fonts/fonts.scss";
@import "../fonts/FF\ Mark/stylesheet";
@import "../fonts/Noe\ Display/stylesheet";
@import "../fonts/nunito_sans/stylesheet";

:root {
  --mobile-menu-height: 4.5rem;
}

html,
body {
    padding: 0;
    margin: 0;
    overscroll-behavior: none;
    background-color: #fdfdfd;
}

mark {
    background-color: transparent !important;
    padding: 0 !important;
    font-weight: bolder;
}
img,
svg {
    display: block !important;
}
a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
}

*:focus {
    outline: none;
}

*:hover {
    outline: none;
}
*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: none;
    border-color: currentColor;
}
.bg-black {
    background-color: $black;
}

.privacy {
    h2 {
        margin-left: 30px !important;
    }
    h3 {
        margin-left: 60px !important;
    }
    div.ant-typography {
        margin-left: 90px !important;
    }
    ul li {
        list-style: disc !important;
        margin-left: 120px !important;
        div.ant-typography {
            margin-left: 0 !important;
        }
    }
}

.site-layout-background {
    background-color: $lightBackground;
}
.striped-gold {
    background: repeating-linear-gradient(
        -60deg,
        #000,
       #000 11px,
        rgba(252,244,42,0.29) 11px,
        rgba(252,244,42,0.29) 13px,
    );
}
.striped-gold-mobile {
    background: repeating-linear-gradient(
        -60deg,
        #000,
       #000 5px,
        rgba(252,244,42,0.29) 5px,
        rgba(252,244,42,0.29) 6px,
    );
}
.striped-gray {
    background: repeating-linear-gradient(
        -45deg,
        $lightBackground,
        $lightBackground 6px,
        $lightBackgroundOverlay 7px,
        $lightBackgroundOverlay 7px
    );
}
.striped {
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 6px,
        $lightBackgroundOverlay 7px,
        $lightBackgroundOverlay 7px
    );
}

.striped-white-full {
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 6px,
        $white 7px,
        $white 7px
    );
}

.striped-white {
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 6px,
        #cccccc55 7px,
        #cccccc55 7px
    );
}

.PhoneInput--focus {
    border: 1px solid #40a9ff !important;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.PhoneInput:hover {
    border: 1px solid #40a9ff !important;
}

.ant-steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ant-steps-icon-dot {
    background: #ffffff !important;
    border: solid 4px #d2d2d2 !important;
}

.ant-steps-item-content {
    margin-top: 2px !important;
}

.ant-steps-item-active .ant-steps-icon-dot {
    border: solid 4px #fde02b !important;
}

.ant-steps-item-tail {
    top: 4px !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #a2a5a6;
}

.jiggle {
    .jiggler:nth-child(2n) {
        animation-name: jiggle1;
        animation-iteration-count: infinite;
    }

    .jiggler:nth-child(2n-1) {
        animation-name: jiggle2;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    @keyframes jiggle1 {
        0% {
            transform: rotate(-1deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(1.5deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes jiggle2 {
        0% {
            transform: rotate(1deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-1.5deg);
            animation-timing-function: ease-out;
        }
    }
}

.serif {
    font-family: "Noe Display", serif;
}
.not-editable {
    input,
    select,
    textarea,
    .ant-select-selector,
    .ant-input-affix-wrapper,
    .ant-input-number,
    .ant-input-number-group-addon,
    .ant-checkbox-checked::after,
    .ant-checkbox:hover .ant-checkbox-inner {
        border: none !important;
        pointer-events: none;
    }
}
input {
    border-radius: 4px;
}
.row-primary {
    background-color: $lightBackground;
}
.ant-drawer-title {
    width: 100%;
}

.animated {
    animation: shake 1s linear infinite;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

.shake {
    animation-name: shake;
}
.htmlContainer * {
    word-break: break-all;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation:
    typing 4s infinite
}

.fadeIn {
    animation: fadeIn 2s
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }

}

.left-sidebar {
    width: 54px;
    &:hover {
        width: 220px;
    }
}
.autocomplete-development .ant-select-selector {
              border-radius: 0.375rem !important;
            }

.border-ai.active{
    transition: all 0.5s ease-in-out;
    width: 50px;
    height: 50px;
    border-radius: 50%;
        animation: rotation 2s infinite linear;
        box-shadow:
        0 0 30px #fff,
        -10px 0 40px #f0f,
        10px 0 40px #0ff;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  width: 4.5px;
  height: 4.5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 3px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 3px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 12px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 21px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(9px, 0);
  }
}
#__next {
    height: 100%;
}

.custom-scroll{
  overflow: auto;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
   visibility: visible;
  transition: visibility 0s 0.2s;
}

.custom-scroll::-webkit-scrollbar {
  background-color:#fff;
  width:16px;
  border-radius: 9999px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color:#fff;
  border-radius: 9999px;
}
.custom-scroll::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 9999px;
  border: 5px solid #fff;
  visibility: hidden;
  transition: visibility 0.2s;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4;
}

.custom-scroll::-webkit-scrollbar-button {
  display:none;
}

.bottom-mobile-menu {
  bottom: var(--mobile-menu-height)
}

.loader {
    width: 80px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    --_m:
      conic-gradient(#0000 50%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}
